import * as Styled from './styled';
import { Cross1Icon } from '@radix-ui/react-icons';
import Button from '@/ui/Button';

export type TFlashType = 'info' | 'success' | 'warning' | 'danger';

type FlashProps = {
  style?: React.CSSProperties;
  center?: boolean;
  type?: TFlashType;
  position?: 'inline' | 'header';
  // variant: 'default' | 'info' | 'success' | 'warning' | 'danger';
  children: React.ReactNode;
  onClose?: () => void;
};

const FlashCloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Styled.FlashCloseButton>
      <Button.Text onClick={onClick}>
        <Cross1Icon />
      </Button.Text>
    </Styled.FlashCloseButton>
  );
};

const FlashInfo: React.FC<FlashProps> = ({ children, style, onClose, center, position = 'inline' }) => {
  return (
    <Styled.FlashInfo style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </Styled.FlashInfo>
  );
};

const FlashSuccess: React.FC<FlashProps> = ({ children, style, onClose, center, position = 'inline' }) => {
  return (
    <Styled.FlashSuccess style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </Styled.FlashSuccess>
  );
};

const FlashWarning: React.FC<FlashProps> = ({ children, style, onClose, center, position = 'inline' }) => {
  return (
    <Styled.FlashWarning style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </Styled.FlashWarning>
  );
};

const FlashDanger: React.FC<FlashProps> = ({ children, style, onClose, center, position = 'inline' }) => {
  return (
    <Styled.FlashDanger style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </Styled.FlashDanger>
  );
};

const Flash: React.FC<FlashProps> = ({ children, style, onClose, center, type, position = 'inline' }) => {
  if (type === 'info')
    return (
      <FlashInfo style={style} onClose={onClose} center={center} position={position}>
        {children}
      </FlashInfo>
    );
  if (type === 'success')
    return (
      <FlashSuccess style={style} onClose={onClose} center={center} position={position}>
        {children}
      </FlashSuccess>
    );
  if (type === 'warning')
    return (
      <FlashWarning style={style} onClose={onClose} center={center} position={position}>
        {children}
      </FlashWarning>
    );
  if (type === 'danger')
    return (
      <FlashDanger style={style} onClose={onClose} center={center} position={position}>
        {children}
      </FlashDanger>
    );
  return (
    <Styled.Flash style={style} $center={center} $position={position}>
      {onClose && <FlashCloseButton onClick={onClose} />}
      {children}
    </Styled.Flash>
  );
};

export default Object.assign(Flash, {
  Info: FlashInfo,
  Success: FlashSuccess,
  Warning: FlashWarning,
  Danger: FlashDanger,
});
