'use client';

import { useState } from 'react';
import { useEffect } from 'react';
import * as Styled from './styled';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { useHelpPanelContext } from './HelpPanelContext';

const HelpPanelAdminInfo: React.FC = () => {
  const { setHighlightTarget } = useHelpPanelContext();
  const [targetIds, setTargetIds] = useState<string[]>([]);

  useEffect(() => {
    const targetElements = document.querySelectorAll(`[data-tutorialid]`);
    const targetElementIds = Array.from(targetElements).map((el) => el.getAttribute('data-tutorialid') as string);
    setTargetIds(targetElementIds || []);
  }, []);

  return (
    <Styled.HelpPanelAdminDisplay>
      <h6>Available highlight sections</h6>
      <small>
        data-tutorialid attributes found on this page,
        <br />
        hover over a section to highlight it
      </small>
      <ul>
        {targetIds.map((id) => (
          <Styled.HelpPanelAdminDisplayListItem
            key={id}
            $available={!!document.querySelector(`[data-tutorialid="${id}"]`)}
            onMouseEnter={() => setHighlightTarget(id)}
            onMouseLeave={() => setHighlightTarget(null)}
          >
            {id}
          </Styled.HelpPanelAdminDisplayListItem>
        ))}
      </ul>
    </Styled.HelpPanelAdminDisplay>
  );
};

const HelpPanel: React.FC = () => {
  const { title, content, isOpen, setIsOpen, highlightTarget, setHighlightTarget } = useHelpPanelContext();

  useEffect(() => {
    const targetElement = document.querySelector(`[data-tutorialid="${highlightTarget}"]`);
    targetElement?.classList.add('glow');
    return () => {
      targetElement?.classList.remove('glow');
    };
  }, [highlightTarget]);

  return (
    content && (
      <Styled.HelpPanelContainer $isOpen={isOpen}>
        <Styled.HelpPanel $isOpen={isOpen}>
          <div className="tab" onClick={() => setIsOpen(!isOpen)} onMouseEnter={() => setIsOpen(true)}>
            <label>
              <QuestionMarkCircledIcon height={16} width={16} /> INFO
            </label>
          </div>
          <div className="content">
            <div className="user-display">
              <h6>{title}</h6>
              {content}
            </div>
            <HelpPanelAdminInfo />
          </div>
        </Styled.HelpPanel>
      </Styled.HelpPanelContainer>
    )
  );
};

const HelpPanelContentStep: React.FC<{ targetTutorialId: string; children: React.ReactNode }> = ({
  targetTutorialId,
  children,
}) => {
  const { setHighlightTarget } = useHelpPanelContext();

  return (
    <div
      className="step"
      onMouseEnter={() => setHighlightTarget(targetTutorialId)}
      onMouseLeave={() => setHighlightTarget(null)}
    >
      {children}
    </div>
  );
};

const HelpPanelContent: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  const { setContent, clearContent } = useHelpPanelContext();
  useEffect(() => {
    setContent(title, children);
    return () => {
      clearContent();
    };
  }, [title, children]);
  return null;
};

export default HelpPanel;
export { HelpPanelContent, HelpPanelContentStep };
