import { createContext, useState, useContext, ReactNode } from 'react';
import HelpPanel from './index';
import * as Styled from './styled';
import { Config } from '@/hooks/useConfig';

interface HelpPanelContextType {
  title?: string;
  content?: ReactNode;
  isOpen?: boolean;
  setContent: (title: string, content: ReactNode) => void;
  clearContent: () => void;
  setIsOpen: (isOpen: boolean) => void;
  highlightTarget: string | null;
  setHighlightTarget: (targetTutorialId: string | null) => void;
}

const HelpPanelContext = createContext<HelpPanelContextType>({
  title: undefined,
  content: undefined,
  isOpen: false,
  setContent: () => {},
  clearContent: () => {},
  setIsOpen: () => {},
  highlightTarget: null,
  setHighlightTarget: () => {},
});

export const HelpPanelContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { config } = Config.useContainer();
  const helpPanelEnabled = !!config?.help_pane_enabled;

  if (!helpPanelEnabled) {
    return children;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [highlightTarget, setHighlightTarget] = useState<string | null>(null);
  const [state, setState] = useState<{
    title?: string;
    content?: ReactNode;
  }>({});

  const setContent = (title: string, content: ReactNode) => {
    setState({ title, content });
  };

  const clearContent = () => {
    setState({});
    setIsOpen(false);
  };

  return (
    <HelpPanelContext.Provider
      value={{
        title: state.title,
        content: state.content,
        setContent,
        clearContent,
        isOpen,
        setIsOpen,
        highlightTarget,
        setHighlightTarget,
      }}
    >
      {state.content ? (
        <Styled.HelpPanelPageWrapper>
          <div style={{ flex: 1 }}>{children}</div>
          <HelpPanel />
        </Styled.HelpPanelPageWrapper>
      ) : (
        children
      )}
    </HelpPanelContext.Provider>
  );
};

export const useHelpPanelContext = () => {
  const context = useContext(HelpPanelContext);
  if (!context) {
    throw new Error('useHelpPanelContext must be used within a HelpPanelContextProvider');
  }
  return context;
};
