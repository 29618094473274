'use client';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Flash, { TFlashType } from '@/ui/Flash';
import Button from '@/ui/Button';
import { Container } from '@/ui/PageLayout';
// import { setFlashMessage } from './setFlashMessage';

export type TMessageContent = {
  title?: string;
  message: string;
  type?: TFlashType;
  position?: 'inline' | 'header';
};

const testContent: TMessageContent = {
  title: 'Test Title',
  message: `Test Message ${Math.floor(Math.random() * 100)}`,
  type: 'success',
};

const DebugButton: React.FC<{ cookieKey: string }> = ({ cookieKey }) => {
  return (
    <div>
      <Button small onClick={() => Cookies.set(cookieKey, JSON.stringify(testContent))}>
        Set Flash Message
      </Button>
    </div>
  );
};

const FlashMessageContainer: React.FC<{ position: 'inline' | 'header'; children: React.ReactNode }> = ({
  position,
  children,
}) => {
  return position === 'inline' ? <Container style={{ flex: 0, gap: 10 }}>{children}</Container> : <>{children}</>;
};

const Display: React.FC<{ content: TMessageContent; cookieKey: string; debug?: boolean }> = ({
  content,
  cookieKey,
  debug = false,
}) => {
  const { title, message, type, position = 'inline' } = content;
  const [isOpen, setIsOpen] = useState(true);
  Cookies.remove(cookieKey);

  const showMessage = !!(isOpen && message);

  return showMessage || debug ? (
    <FlashMessageContainer position={position}>
      {showMessage && (
        <Flash type={type} position={position} onClose={() => setIsOpen(false)}>
          {message}
        </Flash>
      )}
      {debug && <DebugButton cookieKey={cookieKey} />}
    </FlashMessageContainer>
  ) : null;
};

export default Display;
